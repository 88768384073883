import { Customer } from '../../services';
import { Action, Resource } from '../../services/permission/interfaces';
import { PermissionService } from '../../services/permission/permission-service';

export const hasConnectedSearchAppCapability = (customer: Customer) =>
  customer.capabilities.includes('CONNECTED_SEARCH_APP');
export const hasAIAppCapability = (customer: Customer) => customer.capabilities.includes('LUCIDWORKS_AI_APP');
export const hasAIModelTrainingCapability = (customer: Customer) =>
  customer.capabilities.includes('LUCIDWORKS_AI_MODEL_TRAINING');
export const hasAppStudioCapability = (customer: Customer) => customer.capabilities.includes('APP_STUDIO_APP');
export const hasManagedFusionIntegratedSystemCapability = (customer: Customer) => customer.capabilities.includes('MANGEDFUSION_INTEGRATED_SYSTEM');
export const hasEMCapability = (customer: Customer) => customer.capabilities.includes('EXPERIENCE_MANAGER');
export const hasServiceAccountsCapability = (customer: Customer) => customer.capabilities.includes('SERVICE_ACCOUNT');
export const hasSAMLCapability = (customer: Customer) => customer.capabilities.includes('ENTERPRISE_SSO_SAML');
export const hasLucidworksAIEndpointCapability = (customer: Customer) => customer.capabilities.includes('AI_ENDPOINT_APP');
export const hasUserProvisioningCapability = (customer: Customer) => customer.capabilities.includes('USER_PROVISIONING');
export const hasFusionIntegrationsCapability = (customer: Customer) => customer.capabilities.includes('FUSION_INTEGRATIONS');
export const canSeeSettingsPage = (customer: Customer) => {
  const canSeeFusionIntegrations = hasFusionIntegrationsCapability(customer);
  const canSeeUsersAndProvisioning = hasUserProvisioningCapability(customer) && PermissionService.hasPermission(Resource.USERS_PROVISIONING, Action.VIEW);
  const canSeeServiceAccounts = PermissionService.hasPermission(Resource.SERVICE_ACCOUNTS, Action.VIEW) && hasServiceAccountsCapability(customer);
  return (canSeeFusionIntegrations || canSeeUsersAndProvisioning || canSeeServiceAccounts);
}
export const hasLucidworksAnalyticsCapability = (customer: Customer) => customer.capabilities.includes('SIGNAL_STORE');
